import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Props as ImageProps } from 'app/components/Common/Image'
import { Link } from 'gatsby'
import React, { memo } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export interface Props {
  dateCreated: string
  image?: ImageProps
  title: string
  URL: string
}

export const Event = memo(function Event({ image, title, URL }: Props) {
  if (!title || !URL) {
    return null
  }

  return (
    <Container to={URL}>
      <FadeInUp>
        {image ? <LazyLoadImage {...image} /> : null}
        <Title>{title}</Title>
      </FadeInUp>
    </Container>
  )
})

const Container = styled(Link)`
  width: calc(43% - 3rem);
  margin: 3rem 3rem 0 0;
  &:nth-of-type(even) {
    width: calc(57% - 3rem);
  }

  > div {
    height: 27.778vw;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    overflow: hidden;
    position: relative;
    &:hover {
      &:before {
        opacity: 0.8;
      }
      &:after {
        opacity: 1;
      }
      img {
        transform: scale(1.1);
      }
    }
    &:before,
    &:after {
      content: '';
      width: 100%;
      height: 100%;
      backface-visibility: hidden;
      position: absolute;
      top: 0;
      left: 0;
      transition: 0.2s ease-in-out;
      z-index: 2;
    }
    &:before {
      background: ${({ theme }) => theme.colors.variants.neutralDark1};
      opacity: 0.5;
    }
    &:after {
      border: 0.625rem solid
        ${({ theme }) => theme.colors.variants.neutralLight4};
      opacity: 0;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.4s ease-in-out;
  }

  @media (max-width: 1023px) {
    width: 100%;
    margin-top: 1.5rem;
    margin-right: 0;
    &:nth-of-type(even) {
      width: 100%;
    }
    > div {
      height: auto;
      padding-bottom: 80%;
    }
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight3};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.875rem;
  font-weight: 600;
  line-height: 1.875rem;
  text-transform: uppercase;
  position: absolute;
  bottom: 2.5rem;
  right: 2.5rem;
  left: 2.5rem;
  z-index: 2;

  @media (max-width: 1023px) {
    bottom: 6.039vw;
    right: 6.039vw;
    left: 6.039vw;
  }
`
