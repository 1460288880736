import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

import { Event, Props as EventProps } from './Event'

export interface Props {
  events: EventProps[]
}

export const EventsList = memo(function EventsList({ events }: Props) {
  if (events.length < 1) {
    return null
  }

  return (
    <Container row tag="section" wrap>
      {events
        .sort(
          (a, b) =>
            new Date(b.dateCreated).getTime() -
            new Date(a.dateCreated).getTime(),
        )
        .map((item, index) => (
          <Event key={index} {...item} />
        ))}
    </Container>
  )
})

const Container = styled(FlexBox)`
  margin: 3.25rem -3rem 6.25rem 0;
  padding: 0 5.833vw;

  @media (max-width: 1023px) {
    margin: 3rem 0 5.625rem 0;
  }
`
